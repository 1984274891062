export const environment =
{
	production: true,

	apiBaseUrl: 'https://repolog-api-dev1.vergentlms.com/',
	appBaseUrl: 'https://repolog-app-dev1.vergentlms.com/',
	LegacyVergentBaseUrl: 'https://tlxe-dev1.vergentlms.com/',

	// ACV Settings
	TMX_ACVEnabled: false,
	TMX_ACVRootUrl: 'http://ecashqacoreacv.titlemax.biz:8080/ACVApp',
	TMX_ESignUrl: 'https://ecashpaperless-qa.titlemax.com/esign-service-web-ecash/#/session/list?',
	TMX_VirtualStoreRootUrl: 'http://ecashqatlxmicrosvc03.titlemax.biz:8099',
	eCash_AcvUIUrl: 'http://localhost/acv.webui',

	// TLXe Settings
	tlxeBaseUrl: 'https://tlxe-dev1.vergentlms.com',
	acvBaseUrl: 'https://acv-dev1.vergentlms.com/ui/acv/'
};
